"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  aspect-ratio: 1.5/1;
  display: block;
  flex: 1;
  align-self: stretch;
`;

const Silver = css``;

const Gold = css`
  display: none;
`;

const Platinum = css``;

const Diamond = css``;

export const SCompBlogArticleHighlightImageWrapperSmall = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
