"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  position: relative;
  margin-bottom: 32px;
  border-radius: 12px;
  overflow: hidden;
  aspect-ratio: 1.2/1;
  box-shadow: var(--big-elevation);
`;

const Silver = css``;

const Gold = css`
  padding-top: 63.82%;
`;

const Platinum = css``;

const Diamond = css``;

export const SCompBlogReferenceGridItemImageWrapper = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
