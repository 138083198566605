"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CssBody02Med,
  CSSBody02MedXLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  color: var(--text-04);
  ${CssBody02Med};
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css`
  ${CSSBody02MedXLarge};
  font-size: 18px;
  line-height: 140%;
`;

export const SCompBlogReferenceGridItemDescription = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
