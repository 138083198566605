"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSSubtitle01,
  CSSSubtitle01XLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  color: var(--text-04);
  ${CSSSubtitle01};
`;

const Silver = css``;

const Gold = css`
  ${CSSSubtitle01XLarge};
`;

const Platinum = css``;

const Diamond = css``;

export const SCompBlogArticleHighlightInfoDescription = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
